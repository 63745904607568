import { gql } from '@apollo/client';
import { getSubDomain } from '../utils/helpers';
import { apolloQuerySsr } from '../utils/apolloClient';
import GalleryPage from '../components/GalleryPage';
import HomePage from '../components/HomePage';

const galleryQuery = gql`
  query gallery($slug: String!) {
    gallery(slug: $slug) {
      id
      name
      slug
      shortInfo
      longInfo
      featuredImage
      info {
        email
        telephone
        instagram
        twitter
        youtube
      }
    }
  }
`;
const imagesQuery = gql`
  query images($galleryId: ID!) {
    images(galleryId: $galleryId) {
      id
      name
      details
      fileKey
      filePath
      listingOrder
      createdAt
    }
  }
`;

export default function Home(props) {
  const { isGallery, gallery, images, slug } = props;

  if (isGallery) {
    return <GalleryPage gallery={gallery} images={images} />;
  }

  return (
    <HomePage subDomainAvailable={!isGallery && slug ? slug : undefined} />
  );
}

export async function getServerSideProps(ctx) {
  // console.log('server ctx', ctx);
  const { req } = ctx; // query, res, asPath, pathname
  const propsObj = { isGallery: false };

  if (req) {
    const { host } = req.headers;
    const slug = await getSubDomain(host);
    console.log('opening sub domain', slug);
    propsObj.slug = slug;
    if (slug !== null) {
      // is gallery page
      const galleryData = await apolloQuerySsr({
        ctx,
        query: galleryQuery,
        variables: { slug },
        isPrivate: false,
      });
      // console.log('galleryData', galleryData);
      if (galleryData.gallery) {
        propsObj.gallery = galleryData.gallery;
        propsObj.isGallery = true;
      }

      if (galleryData.gallery) {
        const imagesData = await apolloQuerySsr({
          ctx,
          query: imagesQuery,
          variables: { galleryId: galleryData.gallery.id },
          isPrivate: false,
        });
        // console.log('imagesData', imagesData);
        if (imagesData.images) {
          propsObj.images = imagesData.images;
        }
      }
    } else {
      // is home page
    }
    // console.log('slug', slug);
  }
  // console.log('propsObj', propsObj);

  return {
    props: propsObj,
  };
}
