import tw from 'twin.macro';
import Image from 'next/image';

import { AnchorLink } from '@zeiq/web';

const IconElement = ({ icon, ...props }) => (
  <AnchorLink tw="px-4" {...props}>
    {icon}
  </AnchorLink>
);

const GallerySocialIcons = ({ data, hasCenter }) => (
  <div
    css={[
      tw`md:mt-5 mb-10 flex`,
      hasCenter ? tw`justify-center` : tw`justify-start`,
    ]}
  >
    {data.instagram && (
      <IconElement
        href={data.instagram}
        target="_blank"
        rel="noreferrer"
        icon={
          <Image src="/images/social/instagram.png" width="26" height="26" />
        }
      />
    )}
    {data.twitter && (
      <IconElement
        href={data.twitter}
        target="_blank"
        rel="noreferrer"
        icon={<Image src="/images/social/twitter.png" width="26" height="26" />}
      />
    )}
    {data.youtube && (
      <IconElement
        href={data.youtube}
        target="_blank"
        rel="noreferrer"
        icon={<Image src="/images/social/youtube.png" width="26" height="26" />}
      />
    )}
  </div>
);

export default GallerySocialIcons;
