import 'twin.macro';
import { NextSeo } from 'next-seo';

import config from '../utils/config';
import Layout from './Layout';
import HomeHero from './home/HomeHero';
import HomePreview from './home/HomePreview';
import HomeFeatures from './home/HomeFeatures';
import HomeUploadInfo from './home/HomeUploadInfo';
// import HomeDemoVideo from '../components/home/HomeDemoVideo';
import HomePricing from './home/HomePricing';
import HomeReviews from './home/HomeReviews';
import HomeSubscribe from './home/HomeSubscribe';

export default function Home({ subDomainAvailable }) {
  return (
    <Layout>
      <NextSeo
        title="Simple and free gallery websites"
        url={`${config.siteUrl}/`}
      />
      <HomeHero subDomainAvailable={subDomainAvailable} />
      <HomePreview />
      <HomeFeatures />
      <HomeUploadInfo />
      {/* <HomeDemoVideo /> */}
      <HomeReviews />
      <HomePricing />
      <HomeSubscribe />
    </Layout>
  );
}
