/* eslint-disable react/no-unescaped-entities */
import 'twin.macro';
import Button from '../elements/Button';

export default function HomeSubscribe() {
  return (
    <section tw="border-t text-blueGray-700 border-blueGray-100  dark:border-blueGray-900">
      <div tw="container flex flex-wrap items-center px-5 py-24 mx-auto lg:px-16">
        <div tw="flex flex-col w-full p-8 mt-10 lg:w-4/6 md:w-1/2 md:ml-auto md:mt-0 ">
          <h1 tw="text-5xl font-black text-black dark:text-white lg:text-8xl">
            Stay Connected.
          </h1>
          <p tw="mt-4 leading-relaxed dark:text-white">
            Subscribe for updates.
          </p>
        </div>
        <div tw="flex flex-col w-full p-8 lg:w-2/6 md:w-1/2 md:ml-auto lg:mt-10 md:mt-0">
          <div tw="relative mb-4">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="email@example.com"
              tw="w-full px-3 py-1 text-base leading-8 transition-colors duration-200 ease-in-out bg-white border rounded-lg outline-none text-blueGray-700 border-blueGray-300 dark:bg-black dark:border-blueGray-100 focus:border-black"
            />
          </div>
          <Button>Subscribe</Button>
          <p tw="mt-3 text-xs text-blueGray-500">You won't get spam.</p>
        </div>
      </div>
    </section>
  );
}
