import 'twin.macro';
import { useState } from 'react';
import { NextSeo } from 'next-seo';

import config from '../utils/config';
import Layout from './Layout';
import GalleryList from './gallery/GalleryList';
import GalleryHeader from './gallery/GalleryHeader';
import GalleryAbout from './gallery/GalleryAbout';
import GalleryContact from './gallery/GalleryContact';

export default function Gallery({ gallery, images }) {
  const [activeSection, setActiveSection] = useState('Gallery');
  // console.log('gallery', gallery);

  return (
    <Layout isGallery>
      <NextSeo
        title={gallery.name}
        url={`${config.siteUrl}/${gallery.slug}`}
        openGraph={{
          url: `${config.siteUrl}/${gallery.slug}`,
          title: `${gallery.name} | Galllery.co`,
          description: `Checkout ${gallery.name}'s images Galllery.`,
          images: [{ url: 'https://klair.galllery.co/images/logo.svg' }],
          site_name: 'Galllery',
        }}
      />
      <div tw="container px-5  mx-auto lg:px-4">
        <GalleryHeader
          gallery={gallery}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
        />
        {activeSection === 'Gallery' && <GalleryList data={images} />}
        {activeSection === 'About' && <GalleryAbout gallery={gallery} />}
        {activeSection === 'Contact' && <GalleryContact gallery={gallery} />}
      </div>
    </Layout>
  );
}
