import tw, { styled } from 'twin.macro';

const Button = styled.button(({ variant = 'default', size = 'medium' }) => [
  tw`
			font-semibold
			transition duration-500 ease-in-out
			transform
			border
			rounded-xl
			hover:border-black
			hover:bg-gray-900
			hover:text-white
			focus:outline-none
			focus:ring-2
			ring-offset-current
			ring-offset-2
		`,
  variant === 'default' && tw`text-white bg-black dark:border`,
  variant === 'outline' &&
    tw`text-black bg-white dark:text-white dark:bg-black dark:border-gray-900`,
  variant === 'accent' && tw`text-white bg-blue-700`,
  size === 'small' && tw`px-4 py-2`,
  size === 'medium' && tw`px-6 py-3`,
  size === 'big' && tw`px-16 py-4`,
]);

export default Button;
