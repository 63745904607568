import 'twin.macro';

export default function HomePreview() {
  return (
    <section>
      <div tw="container flex flex-col items-center justify-center px-6 py-6 mx-auto rounded-lg lg:px-40 lg:-mt-20">
        <img
          tw="object-cover object-center sm:mt-2 lg:mt-10 w-full mb-6 rounded-xl border-blueGray-200 dark:border-blueGray-900"
          alt="Galllery Preview"
          src="./images/home-hero.png"
          style={{
            transform: '',
            // 'perspective(1000px)rotateX( 30deg) rotateY(0) rotateZ(0)', //for rotate image
            transformOrigin: 'center center',
          }}
        />
      </div>
      <a
        href="https://www.producthunt.com/posts/galllery?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-galllery"
        target="_blank"
        rel="noreferrer"
        tw="flex justify-center"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303570&theme=dark"
          alt="Galllery - Create your personal portfolio gallery website | Product Hunt"
          style={{ width: '250px', height: '54px' }}
          width="250"
          height="54"
        />
      </a>
    </section>
  );
}
