import tw from 'twin.macro';

const GalleryNavElement = ({ title, activeSection, setActiveSection }) => (
  <button
    type="button"
    onClick={() => setActiveSection(title)}
    data-target="panel-1"
    className="tab active"
    css={[
      tw`py-4 w-full md:px-20 block hover:text-blue-500 focus:outline-none  border-b-2 font-medium`,
      activeSection === title && tw`border-blue-500 text-blue-500`,
    ]}
  >
    {title}
  </button>
);

export default GalleryNavElement;
