import 'twin.macro';
import { Button } from '@zeiq/web';

const EmptyState = ({ title, message, btnTitle, btnLink }) => {
  return (
    <div tw="flex flex-col w-4/6 m-auto items-center p-10 text-center bg-yellow-100">
      {title && (
        <h4 tw="text-xl font-light text-center text-red-300 uppercase ">
          {title}
        </h4>
      )}
      {message && (
        <h3 tw="box-border text-xl font-black leading-tight tracking-tight text-black border-solid mt-1 mb-4">
          {message}
        </h3>
      )}
      {btnLink && btnTitle && (
        <a href={btnLink}>
          <Button>{btnTitle}</Button>
        </a>
      )}
    </div>
  );
};

export default EmptyState;
