import 'twin.macro';
import { Heading } from '@zeiq/web';

import GallerySocialIcons from './GallerySocialIcons';
import EmptyState from '../elements/EmptyState';

const GalleryContact = ({ gallery }) => {
  if (!gallery.info) {
    return (
      <EmptyState
        title="No contact information updated for this Galllery yet."
        message="Why not insert some information at Gallery portal?"
        btnLink="https://www.galllery.co/auth/account"
        btnTitle="Manage my account"
      />
    );
  }

  return (
    <div tw="max-w-screen-xl md:my-14 md:grid gap-20 md:flex px-4 md:px-8 lg:px-40 mx-auto justify-around rounded-lg">
      <div tw="flex-initial">
        <Heading type="h2" tw="text-4xl lg:text-5xl font-bold leading-tight">
          Get in touch
        </Heading>
      </div>
      {gallery.info && (
        <div tw="md:flex flex-1 flex-row	justify-around items-center">
          <div tw="mt-6 md:mt-0">
            <Heading type="h3" tw="text-2xl mb-4 font-bold leading-tight">
              Say hello
            </Heading>
            {gallery.info.email && (
              <a
                href={`mailto:${gallery.info.email}`}
                tw="text-xl block font-light text-gray-700 dark:text-gray-300"
              >
                ✉️ {gallery.info.email}
              </a>
            )}
            {gallery.info.telephone && (
              <a
                href={`tel:${gallery.info.telephone}`}
                tw="text-xl block mt-1 font-light text-gray-700 dark:text-gray-300"
              >
                📞 {gallery.info.telephone}
              </a>
            )}
          </div>
          <div tw="mt-6 md:mt-0">
            {gallery.info && (
              <Heading
                type="h3"
                tw="text-2xl mb-6 md:mt-2 font-bold leading-tight"
              >
                Connect at
              </Heading>
            )}
            <GallerySocialIcons data={gallery.info} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryContact;
