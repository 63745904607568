import 'twin.macro';
import NextImage from 'next/image';
import styled from 'styled-components';

const Item = styled.div`
  min-height: 300px;
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out 0s;
`;

const GalleryItem = ({ item }) => {
  // console.log('item', item);
  if (!item.filePath) {
    return null;
  }

  return (
    <Item tw="relative bg-gray-200 shadow">
      <NextImage
        src={item.filePath}
        alt={item.name}
        title={item.name}
        layout="fill"
        objectFit="cover"
        quality={80}
      />
    </Item>
  );
};

export default GalleryItem;
