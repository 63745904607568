import 'twin.macro';
import ReactMarkdown from 'react-markdown';
import { isNull } from 'lodash';

import GallerySocialIcons from './GallerySocialIcons';
import EmptyState from '../elements/EmptyState';

const GalleryAbout = ({ gallery }) => {
  if (isNull(gallery.longInfo)) {
    return (
      <EmptyState
        title="No about information updated for this Galllery yet."
        message="Why not insert some information at Gallery portal?"
        btnLink="https://www.galllery.co/auth/account"
        btnTitle="Manage my account"
      />
    );
  }

  return (
    <div tw="text-center px-4 md:px-6 lg:px-20">
      <div tw="mt-6 mb-20 leading-8">
        <ReactMarkdown>{gallery.longInfo || 'About me'}</ReactMarkdown>
      </div>
      {gallery.info && <GallerySocialIcons hasCenter data={gallery.info} />}
    </div>
  );
};

export default GalleryAbout;
