import tw from 'twin.macro';
import swal from 'sweetalert';
import { useRouter } from 'next/router';

const YesIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
);
const NoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const content = [
  {
    id: 1,
    title: 'START',
    price: 'Free',
    hasBlackBg: false,
    features: [
      { id: 1, icon: YesIcon, title: '1000 images storage' },
      { id: 2, icon: YesIcon, title: 'Free Custom Domain' },
      { id: 3, icon: NoIcon, title: 'Google Analytics' },
      { id: 4, icon: NoIcon, title: 'No Branding' },
    ],
  },
  {
    id: 2,
    title: 'PRO',
    price: '$3/month',
    hasBlackBg: true,
    features: [
      { id: 1, icon: YesIcon, title: 'Unlimited images storage' },
      { id: 2, icon: YesIcon, title: 'Free Custom Domain' },
      { id: 3, icon: YesIcon, title: 'Google Analytics' },
      { id: 4, icon: YesIcon, title: 'No Branding' },
    ],
  },
];

function FeatureItem({ data, hasBlackBg }) {
  return (
    <p
      css={[
        tw`flex items-center mb-2 leading-snug`,
        hasBlackBg
          ? tw`text-white dark:text-black`
          : tw`text-blueGray-700 dark:text-blueGray-100`,
      ]}
    >
      <span tw="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
        {data.icon}
      </span>
      {data.title}
    </p>
  );
}

export default function HomePricing() {
  const router = useRouter();

  const handleCheckout = (item) => {
    if (item.id === 2) {
      return swal('Pro plan launching soon!');
    }
    return router.push('/auth/register');
  };

  return (
    <section tw="text-blueGray-400 " id="pricing">
      <div tw="container flex flex-col items-center px-5 pt-4 pb-16 mx-auto lg:px-24 md:px-10 ">
        <div tw="flex flex-col w-full mb-12 text-left lg:text-center">
          <h1 tw="mb-6 text-4xl font-bold text-black dark:text-white md:text-8xl lg:text-6xl ">
            Optional: Go Pro!
          </h1>
          <p tw="mx-auto text-lg leading-snug text-blueGray-500 dark:text-blueGray-400 lg:w-1/2">
            Very accessible pricing.
          </p>
        </div>
        <div tw="flex flex-wrap lg:px-12 w-11/12 md:w-3/4">
          {content.map((item) => (
            <div key={item.id} tw="w-full p-4 xl:w-1/2 md:w-1/2">
              <div
                css={[
                  tw`relative flex flex-col h-full p-6 overflow-hidden rounded-2xl`,
                  item.hasBlackBg
                    ? tw`bg-black shadow-xl dark:bg-white hover:shadow`
                    : tw`border-2 border-blueGray-100 hover:shadow-xl dark:border-blueGray-800`,
                ]}
              >
                <h2
                  css={[
                    tw`mb-1 text-sm font-medium tracking-widest `,
                    item.hasBlackBg && tw`text-white dark:text-black`,
                  ]}
                >
                  {item.title}
                </h2>
                <h1
                  css={[
                    tw`pb-4 mb-4 text-5xl font-black leading-none border-blueGray-200`,
                    item.hasBlackBg
                      ? tw`text-white dark:text-black`
                      : tw`text-blueGray-400`,
                  ]}
                >
                  {item.price}
                </h1>
                {item.features.map((feature) => (
                  <FeatureItem
                    key={feature.id}
                    data={feature}
                    hasBlackBg={item.hasBlackBg}
                  />
                ))}
                <button
                  type="button"
                  onClick={() => handleCheckout(item)}
                  css={[
                    tw`flex items-center w-full px-4 py-2 mt-auto text-white border-0 transition duration-500 ease-in-out transform rounded-xl focus:outline-none focus:ring-2 ring-offset-current ring-offset-2`,
                    item.hasBlackBg
                      ? tw`text-white bg-blue-700 hover:bg-blue-900`
                      : tw`bg-blueGray-400 hover:bg-blueGray-500`,
                  ]}
                >
                  Sign-up
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    tw="w-6 h-6 ml-auto"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </button>
                {/* <p tw="mt-3 text-xs text-blueGray-500">
                  *VAT si always included in every purchase made throug our
                  website
                </p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
