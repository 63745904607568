/* eslint-disable react/no-unescaped-entities */
import 'twin.macro';
import Link from 'next/link';
import Typewriter from 'typewriter-effect/dist/core';

import { useEffect } from 'react';
import Button from '../elements/Button';

export default function HomeHero({ subDomainAvailable }) {
  useEffect(() => {
    const init = () => {
      // eslint-disable-next-line no-new
      new Typewriter('#typewriter', {
        strings: ['klair.galllery.co', 'john.galllery.co'],
        autoStart: true,
        loop: true,
      });
    };
    init();
  }, []);
  return (
    <section tw="text-black dark:text-white  lg:pt-28">
      <div tw="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
        <div tw="flex flex-col w-full mb-5 text-left lg:text-center">
          <h1 tw="mb-6 text-4xl font-black text-black dark:text-white md:text-5xl lg:text-7xl ">
            Create your personal <br tw="hidden lg:block" />
            portfolio website.
          </h1>
          <p tw="mx-auto text-lg leading-snug text-blueGray-500 lg:w-1/2 dark:text-blueGray-300">
            Easy to setup, with custom link, and it's FREE.
            <br />
            Examples:{' '}
            <a
              tw="font-bold"
              target="_blank"
              href="https://klair.galllery.co/"
              rel="noreferrer"
            >
              <span id="typewriter" />
            </a>
          </p>
        </div>
        <div tw="flex flex-col mb-2 lg:justify-center md:flex-row">
          <Link href="/auth/register">
            <a>
              <Button tw="mt-4" variant="accent" size="big">
                🪄 Get Started
              </Button>
            </a>
          </Link>
        </div>
        <p tw="mt-2 mb-10 text-sm italic text-blueGray-500 md:mx-auto md:text-center ">
          {subDomainAvailable
            ? `${subDomainAvailable}.galllery.co is available!`
            : 'No credit card required'}
        </p>
      </div>
    </section>
  );
}
