import 'twin.macro';
import NextImage from 'next/image';
import styled from 'styled-components';

import GalleryNavElement from './GalleryNavElement';

const AvatarContainer = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 75px;
  overflow: hidden;
  margin: 0 auto 15px auto;
`;

export default function GalleryHeader({
  gallery,
  activeSection,
  setActiveSection,
}) {
  return (
    <div tw="pt-10 mx-auto max-w-7xl">
      <div tw="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
        {gallery.featuredImage && (
          <AvatarContainer>
            <NextImage
              src={gallery.featuredImage}
              alt="Upload new gallery picture"
              title="Upload new gallery picture"
              layout="fill"
              objectFit="cover"
              quality={80}
            />
          </AvatarContainer>
        )}
        <h1 tw="mb-4 text-3xl font-extrabold leading-none tracking-normal  md:text-6xl md:tracking-tight">
          <span tw="block w-full py-2 text-transparent bg-clip-text leading-10 bg-gradient-to-r from-green-400 to-purple-500 inline">
            {gallery.name}
          </span>
        </h1>
        {!gallery.details && (
          <p tw="px-0 mb-8 text-lg  md:text-xl lg:px-24">{gallery.shortInfo}</p>
        )}
        <div tw="pt-2 flex justify-center mb-4">
          <GalleryNavElement
            title="Gallery"
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
          <GalleryNavElement
            title="About"
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
          <GalleryNavElement
            title="Contact"
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </div>
      </div>
    </div>
  );
}
