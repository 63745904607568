import 'twin.macro';
import styled from 'styled-components';
import { SRLWrapper } from 'simple-react-lightbox';
import Masonry from 'react-masonry-css';

import GalleryItem from './GalleryItem';
import EmptyState from '../elements/EmptyState';

const Container = styled.div`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -25px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 25px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }
`;

const options = {
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
  caption: {
    showCaption: true,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

// https://helbert.darkroom.tech/
// https://emilia.lekoarts.de/
const Gallery = ({ data }) => {
  if (data.length === 0) {
    return (
      <EmptyState
        title="No images upload to this Galllery yet."
        message="Why not start uploading some images at Gallery portal?"
        btnLink="https://www.galllery.co/auth/account"
        btnTitle="Manage my account"
      />
    );
  }
  return (
    <SRLWrapper options={options}>
      <Container tw="p-8 sm:p-4 mx-auto">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data.map((item) => {
            if (item === null) {
              return null;
            }
            return <GalleryItem key={item.id} item={item} />;
          })}
        </Masonry>
      </Container>
    </SRLWrapper>
  );
};

export default Gallery;
