/* eslint-disable react/no-unescaped-entities */
import 'twin.macro';
import config from '../../utils/config';

const content = [
  {
    id: 1,
    title: 'Simple',
    details:
      'Easy to manage and upload your Galllery, either via web portal or mobile app.',
    icon: '👍',
  },
  {
    id: 2,
    title: 'Sharing',
    details:
      'Get a free custom web link to share on social media or with your family and friends',
    icon: '🚀',
  },
  {
    id: 3,
    title: 'Free',
    details: `Build free images gallery collections and use all of the core features – for free!`,
    icon: '🎉',
  },
];

function HomeFeatureItem({ data }) {
  return (
    <div tw="p-4 lg:w-1/3">
      <div tw="flex flex-col h-full p-8 shadow rounded-xl">
        <div tw="inline-flex items-center mb-3 ">
          <div tw="inline-flex items-center justify-center w-12 h-12 text-black rounded-full bg-blueGray-200 dark:bg-blueGray-700 dark:text-white">
            {data.icon}
          </div>
          <h2 tw="ml-4 text-2xl font-black text-black dark:text-white">
            {data.title}
          </h2>
        </div>
        <div tw="flex-grow mt-6">
          <p tw="mx-auto text-lg leading-snug text-blueGray-500 dark:text-blueGray-400 ">
            {data.details}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function HomeFeatures() {
  return (
    <section tw="text-blueGray-600 dark:text-blueGray-300 " id="features">
      <div tw="container px-5 py-10 mx-auto lg:px-20">
        <div tw="flex flex-col w-full mb-12 text-left lg:text-center">
          <h2 tw="mb-1 text-xs font-semibold tracking-widest uppercase text-blueGray-500 ">
            what is {config.siteName}?
          </h2>
          <h1 tw="mb-6 text-4xl font-bold text-black dark:text-white md:text-8xl lg:text-6xl ">
            Build a one-page images gallery {` `}
            <br tw="hidden lg:block" />
            for pretty much anything
          </h1>
          <p tw="mx-auto text-lg leading-snug text-blueGray-500 dark:text-blueGray-400 lg:w-1/2">
            Whether it's a personal gallery, or something a bit more
            professional, {config.siteName} has you covered. Simple, responsive,
            and yup — totally free.
          </p>
        </div>
        <div tw="flex flex-wrap -m-4">
          {content.map((item) => (
            <HomeFeatureItem key={item.id} data={item} />
          ))}
        </div>
      </div>
    </section>
  );
}
