import 'twin.macro';

const content = [
  {
    id: 1,
    personName: 'Haana Pelkonen',
    personRole: 'Street Photographer',
    personImage: '/images/reviews/p1.jpeg',
    comment:
      'Keep up the excellent work. Great job, I will definitely be sharing with others! Very easy to use. Since I created my Gallery, I made lots of new social fans.',
  },
  {
    id: 2,
    personName: 'Jailson Pio',
    personRole: 'Landscape Photographer',
    personImage: '/images/reviews/p2.jpg',
    comment: `It's just amazing. It's just amazing the process. I can't say enough about Galllery. We've used Gallery for the last few months and we had no regrets whatsoever.`,
  },
];

function HomeReviewItem({ data }) {
  return (
    <div tw="w-full p-4 md:w-1/2">
      <div tw="h-full p-8 shadow border-blueGray-100 rounded-xl dark:border-blueGray-900 ">
        <p tw="mb-6 text-base leading-snug text-blueGray-500 dark:text-blueGray-400 lg:text-lg">
          {data.comment}
        </p>
        <a tw="inline-flex items-center">
          <img
            alt="testimonial"
            src={data.personImage}
            tw="flex-shrink-0 object-cover object-center w-12 h-12 rounded-full"
          />
          <span tw="flex flex-col flex-grow pl-4">
            <span tw="font-medium text-black dark:text-white ">
              {data.personName}
            </span>
            <span tw="text-sm text-blueGray-500">{data.personRole}</span>
          </span>
        </a>
      </div>
    </div>
  );
}

export default function HomeReviews() {
  return (
    <section tw="text-black dark:text-white " id="reviews">
      <div tw="container px-5 py-24 mx-auto lg:px-20">
        <div tw="flex flex-col w-full mb-12 text-left lg:text-center">
          <h1 tw="mb-6 text-4xl font-bold text-black dark:text-white md:text-8xl lg:text-6xl ">
            Our customers loves us,
            <br tw="hidden lg:block" />
            and we love them too.
          </h1>
          <p tw="mx-auto text-lg leading-snug text-blueGray-500 dark:text-blueGray-400 lg:w-1/2">
            This is what they have to say..{' '}
          </p>
        </div>
        <div tw="flex flex-wrap -m-4">
          {content.map((item) => (
            <HomeReviewItem key={item.id} data={item} />
          ))}
        </div>
      </div>
    </section>
  );
}
