import 'twin.macro';

export default function HomeUploadInfo() {
  return (
    <section tw="text-blueGray-400 " id="features">
      <div tw="container flex flex-col items-center px-5 py-16 mx-auto lg:px-24 md:px-10 lg:py-20 md:flex-row">
        <div tw="flex flex-col items-center w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 lg:mr-20 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0 lg:text-center">
          <h2 tw="mb-4 text-sm font-medium tracking-widest text-lightBlue-500 ">
            how to?
          </h2>
          <h1 tw="mb-8 text-2xl font-bold text-left text-black dark:text-white lg:text-5xl ">
            Upload files directly from your mobile phone
          </h1>
          <p tw="mb-8 text-base leading-snug text-left text-blueGray-400 lg:text-xl">
            Use our Apple Shortcut built to upload images directly from your
            Photos app to your website. Instant! No more logging into the
            website or doing any other steps.
          </p>
        </div>
        <div tw="w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
          <img
            tw="object-cover object-center rounded-xl"
            alt="galllery"
            src="./images/upload-img.png"
          />
        </div>
      </div>
    </section>
  );
}
